import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ON_BOARDING_DEFAULT_LOCATION, onBoardingUpdateAction } from "../../reducers/onboarding";
import './OnBoarding.scss';
import { OnBoardingBirthdate } from "./components/OnBoardingBirthdate";
import { OnBoardingLocation } from "./components/OnBoardingLocation";
import { OnBoardingName } from "./components/OnBoardingName";
import { OnBoardingOtherType } from "./components/OnBoardingOtherType";
import { OnBoardingSexSelect } from "./components/OnBoardingSexSelect";
import {OnBoardingAbout} from "./components/OnBoardingAbout";
import { OnBoardingType } from "./components/OnBoardingType";
import { OnBoardingPublicPhotos } from './components/OnBoardingPublicPhotos';
import {OnBoardingAvatar} from "./components/OnBoardingAvatar";
import {OnBoardingPrivatePhotos} from "./components/OnBoardingPrivatePhotos";
import {PROFILE_TYPE} from "../../types/models/profile";

export function OnBoarding({profileType}: {profileType: PROFILE_TYPE | false}) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.user.user)
  const step = useSelector(state => state.onBoarding.step)
  const state = useSelector((state) => state);

  useEffect(() => {

    if (user !== null) {
      dispatch(onBoardingUpdateAction({
        values: {
          sex: user.sex,
          location: user.userLocation.location ? user.userLocation : ON_BOARDING_DEFAULT_LOCATION,
          step: profileType ? 'name' : 'profileType',
          profileType : profileType ? profileType : null
        }
      }))
    }
  }, [])

  const getBody = () => {

    if (step === 'sex') {
      return <OnBoardingSexSelect/>
    }

    if (step === 'location') {
      return <OnBoardingLocation/>
    }

    if (step === 'profileType') {
      return <OnBoardingType/>
    }

    if (step === 'name') {
      return <OnBoardingName profileType={profileType}/>
    }

    if (step === 'avatar') {
      return <OnBoardingAvatar/>
    }

    if (step === 'publicPhotos') {
      return <OnBoardingPublicPhotos/>
    }

    if (step === 'privatePhotos') {
      return <OnBoardingPrivatePhotos/>
    }

    if (step === 'birthdate') {
      return <OnBoardingBirthdate/>
    }

    if (step === 'about') {
      return <OnBoardingAbout/>
    }

    if (step === 'other') {
      return <OnBoardingOtherType/>
    }

  }

  return (
    <div className={`on-boarding container`}>
      {getBody()}
    </div>
  )

}
